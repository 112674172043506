<template>
    <div>{{ row.item.vat_rate }}%</div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>